/*

Keeps track of the frames that have been loaded into the DOM.

*/

import type { Logger } from 'static/logger';

type FramePair = {
    transactionId: string;
    form?: HTMLIFrameElement;
    results?: Array<{ frame: HTMLIFrameElement; key: number }>;
    searchOnLoad?: boolean;
};

const loadedFramePairs = new Map<string, FramePair>();

export const getAllLoadedFramePairs = (): Array<FramePair> => [
    ...loadedFramePairs.values(),
];

export const getLoadedFramePair = (pairKey: string): FramePair | undefined =>
    loadedFramePairs.get(pairKey);

export const saveFormFrame = (params: {
    pairKey: string;
    transactionId: string;
    frame: HTMLIFrameElement;
}) => {
    loadedFramePairs.set(params.pairKey, {
        transactionId: params.transactionId,
        form: params.frame,
    });
};

export const saveResultsFrame = (params: {
    pairKey: string;
    transactionId: string;
    frame: HTMLIFrameElement;
    searchOnLoad: boolean;
    resultKey: number;
    logger: Logger;
}) => {
    const existing = loadedFramePairs.get(params.pairKey);

    if (!existing)
        params.logger.warn(
            `results frame loaded without a matching form frame.`,
        );

    loadedFramePairs.set(params.pairKey, {
        transactionId: params.transactionId,
        form: existing?.form,
        results: [
            ...(existing?.results || []),
            { frame: params.frame, key: params.resultKey },
        ],
        searchOnLoad: params.searchOnLoad,
    });
};
