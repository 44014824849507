import { z } from 'zod';

// c.f. 'accreditations' field at
// https://www.campusexplorer.com/api/docs/program-ad-search/1/ NOTE: this list
// includes additional unpublished values, see
// lib/CE/Form/API/ProgramAdSearch.pm in the CE directory for all our internal
// values.

export type Accreditations = z.infer<typeof AccreditationsSchema>;
export const AccreditationsSchema = z.enum([
    'AACSB ACCREDITED',
    'AAPC ACCREDITED',
    'AAQEP ACCREDITED',
    'ABA ACCREDITED',
    'ABAI ACCREDITED',
    'ABET ACCREDITED',
    'ABHES ACCREDITED',
    'ACBSP ACCREDITED',
    'ACCET ACCREDITED',
    'ACCJC ACCREDITED',
    'ACCSC ACCREDITED',
    'ACEN ACCREDITED',
    'ACEND ACCREDITED',
    'ACICS ACCREDITED',
    'ACOTE ACCREDITED',
    'ACPSP ACCREDITED',
    'AHIMA ACCREDITED',
    'APA ACCREDITED',
    'ARC-PA ACCREDITED',
    'ARRT ACCREDITED',
    'ASHA ACCREDITED',
    'CAAHEP ACCREDITED',
    'CACREP ACCREDITED',
    'CACREP ADHERENCE',
    'CAEP ACCREDITED',
    'CAHIIM ACCREDITED',
    'CAHME ACCREDITED',
    'CCNE ACCREDITED',
    'CEPH ACCREDITED',
    'COAMFTE ACCREDITED',
    'COE ACCREDITED',
    'CSWE ACCREDITED',
    'DEAC ACCREDITED',
    'HLC ACCREDITED',
    'IACBE ACCREDITED',
    'IACSTE ACCREDITED',
    'INSTITUTIONALLY ACCREDITED',
    'MPCAC ACCREDITED',
    'MSCHE ACCREDITED',
    'NAACLS ACCREDITED',
    'NASAC ACCREDITED',
    'NASP ACCREDITED',
    'NASPAA ACCREDITED',
    'NATIONALLY ACCREDITED',
    'NAVTA ACCREDITED',
    'NEASC ACCREDITED',
    'NECHE ACCREDITED',
    'NLNAC ACCREDITED',
    'NWCCU ACCREDITED',
    'REGIONALLY ACCREDITED',
    'SACSCOC ACCREDITED',
    'SACSOC ACCREDITED',
    'SBEC ACCREDITED',
    'WSCUC ACCREDITED',
]);
