import type { FormLoadedEvent } from 'common/domain-events';
import { getLoadedFramePair } from 'static/frames/loaded-frames';
import { bubbleEvent } from './bubble-event';

// On a FormLoaded event, re-emit the event to our parent page, with added data
// that says whether the form is on screen & more
export const handleFormLoaded = (params: { event: FormLoadedEvent }) => {
    const framePair = getLoadedFramePair(params.event.pairKey);
    const formFrame = framePair?.form;
    if (!formFrame) return;
    const yPosition = formFrame.getBoundingClientRect().top;
    const onScreen = yPosition <= window.innerHeight;

    bubbleEvent({
        event: params.event,
        extra: {
            height: params.event.height,
            onScreen,
            pairKey: params.event.pairKey,
            searchId: params.event.searchId ?? undefined,
            transactionId: params.event.transactionId,
            width: params.event.width,
        },
    });
};
