import type { HashConfig } from 'static/hash-config';

const getOrigin = (hostname: string) => {
    if (hostname.startsWith('http://')) return hostname;
    if (hostname.startsWith('https://')) return hostname;
    if (hostname.startsWith('localhost:')) {
        return `http://${hostname}`;
    }
    return `https://${hostname}`;
};

// Checks if the currently running script does not match the host in the params.
export const needsHostChange = (
    params: HashConfig,
): params is { host: string } => {
    if (!params.host) return false;

    const currentScript = document.currentScript as HTMLScriptElement;
    if (!currentScript) return false;

    const currentURL = new URL(currentScript.src);
    const scriptHost = currentURL.origin;

    return scriptHost !== getOrigin(params.host);
};

// Reload script with new host
export const setScriptHost = (params: { host: string }) => {
    const currentScript = document.currentScript as HTMLScriptElement;
    if (!currentScript) throw new Error('setScriptHost(): no currentScript');

    const newOrigin = getOrigin(params.host);
    const path = new URL(currentScript.src).pathname;
    const newSrc = `${newOrigin}${path}`;

    // Remove the current script element
    currentScript.remove();

    // Create a new script element with the desired host
    const newScript = document.createElement('script');
    newScript.src = newSrc;
    document.body.appendChild(newScript);

    return true;
};
