/*

Creates and inserts an iframe into the parent element
*/

const insert = (params: {
    parentEl: HTMLElement;
    url: string;
    position: string;
    height: string;
}): HTMLIFrameElement => {
    const frame = document.createElement('iframe');
    frame.src = params.url;
    frame.style.width = '100%';
    frame.style.height = params.height;
    frame.style.border = 'none';
    frame.style.position = params.position;
    params.parentEl.replaceChildren(frame);
    return frame;
};

export const insertFrame = (params: {
    url: string;
    parentEl: HTMLElement;
    position: string;
    height: string;
}): HTMLIFrameElement => {
    const frame = insert({
        parentEl: params.parentEl,
        url: params.url,
        position: params.position,
        height: params.height,
    });
    return frame;
};
