/*

Helper to extract the error message in a try block.

*/

const toError = (input: unknown): Error => {
    if (input instanceof Error) return input;
    if (typeof input === 'string') return new Error(input);
    if (typeof input === 'object' && input !== null)
        return new Error(JSON.stringify(input));
    return new Error(String(input));
};

const formatMessage = (error: Error, opts?: { includeStack?: boolean }) => {
    const { message, stack } = error;
    if (opts?.includeStack) return `${message}\n${stack ?? 'NO STACK'}`;
    return message;
};

export function getErrorMessage(
    input: unknown,
    opts?: { includeStack?: boolean },
): string {
    const error = toError(input);
    return formatMessage(error, opts);
}
