import { z } from 'zod';

// c.f. 'programLength' field at
// https://www.campusexplorer.com/api/docs/program-ad-search/1/

export type ProgramLength = z.infer<typeof ProgramLengthSchema>;
export const ProgramLengthSchema = z.enum([
    '1-YEAR PROGRAM',
    '2-YEAR PROGRAM',
    '18-MONTH PROGRAM',
    'ACCELERATED',
    'ADVANCED STANDING OPTION',
]);
