import { z } from 'zod';
import { AccreditationsSchema } from './enums/accreditations';
import { DegreeSchema } from './enums/degree';
import { ModalitySchema } from './enums/modality';
import { ProgramLengthSchema } from './enums/program-length';
import { ProgramRequirementsSchema } from './enums/program-requirements';

export type AdClickedEvent = z.infer<typeof adClickedEventSchema>;
const adClickedEventSchema = z.object({
    eventName: z.literal('ad_clicked'),
    capturedAt: z.number(),
    clickoutSearchResultItemId: z.string(),
    clickTarget: z.string(),
    index: z.number(),
    programName: z.string().optional(),
    schoolName: z.string().optional(),
    searchId: z.string(),
    transactionId: z.string(),
    url: z.string(),
});

export type AdLoadedEvent = z.infer<typeof adLoadedEventSchema>;
const adLoadedEventSchema = z.object({
    eventName: z.literal('ad_loaded'),
    capturedAt: z.number(),
    clickoutSearchResultItemId: z.string(),
    index: z.number(),
    programName: z.string().optional(),
    schoolName: z.string().optional(),
    searchId: z.string(),
    transactionId: z.string(),
});

export type CdoViewMoreClickedEvent = z.infer<
    typeof cdoViewMoreClickedEventSchema
>;
const cdoViewMoreClickedEventSchema = z.object({
    eventName: z.literal('cdo_view_more_clicked'),
    capturedAt: z.number(),
});

export type FormDropdownClosedEvent = z.infer<
    typeof formDropdownClosedEventSchema
>;
const formDropdownClosedEventSchema = z.object({
    eventName: z.literal('form_dropdown_closed'),
    capturedAt: z.number(),
    pairKey: z.string(),
});

export type FormDropdownOpenedEvent = z.infer<
    typeof formDropdownOpenedEventSchema
>;
const formDropdownOpenedEventSchema = z.object({
    eventName: z.literal('form_dropdown_opened'),
    capturedAt: z.number(),
    pairKey: z.string(),
});

export type FormHeightChangedEvent = z.infer<
    typeof formHeightChangedEventSchema
>;
const formHeightChangedEventSchema = z.object({
    eventName: z.literal('form_height_changed'),
    capturedAt: z.number(),
    height: z.number(),
    pairKey: z.string(),
});

export type FormLoadedEvent = z.infer<typeof formLoadedEventSchema>;
const formLoadedEventSchema = z.object({
    eventName: z.literal('form_loaded'),
    capturedAt: z.number(),
    height: z.number(),
    pairKey: z.string(),
    searchId: z.string().nullable(),
    transactionId: z.string(),
    width: z.number(),
});

export type FormSubmittedEvent = z.infer<typeof formSubmittedEventSchema>;
const formSubmittedEventSchema = z.object({
    eventName: z.literal('form_submitted'),
    capturedAt: z.number(),
    accreditations: AccreditationsSchema.optional(),
    degreeLevel: DegreeSchema.optional(),
    externalResultsURL: z.string().optional(),
    fieldOfStudy: z.string().optional(),
    major: z.string().optional(),
    modality: ModalitySchema.optional(),
    numResults: z.number(),
    pairKey: z.string(),
    programLength: ProgramLengthSchema.optional(),
    requirements: ProgramRequirementsSchema.optional(),
    searchId: z.string(),
    transactionId: z.string(),
});

export type NavigatedToExternalResultsEvent = z.infer<
    typeof navigatedToExternalResultsSchema
>;
const navigatedToExternalResultsSchema = z.object({
    eventName: z.literal('navigating_to_external_results_location'),
    capturedAt: z.number(),
    searchId: z.string(),
    url: z.string(),
});

export type NoOffersFoundEvent = z.infer<typeof noOffersFoundEventSchema>;
const noOffersFoundEventSchema = z.object({
    eventName: z.literal('no_offers_found'),
    capturedAt: z.number(),
    externalResultsURL: z.string().optional(),
    pairKey: z.string(),
    searchId: z.string().optional(),
    transactionId: z.string(),
});

export type ResultsHeightChangedEvent = z.infer<
    typeof resultsHeightChangedEventSchema
>;
const resultsHeightChangedEventSchema = z.object({
    eventName: z.literal('results_height_changed'),
    capturedAt: z.number(),
    height: z.number(),
    pairKey: z.string(),
    resultKey: z.number(),
});

export type ResultsLoadedEvent = z.infer<typeof resultsLoadedEventSchema>;
const resultsLoadedEventSchema = z.object({
    eventName: z.literal('results_loaded'),
    capturedAt: z.number(),
    clickoutSearchResultItemIds: z.array(z.string()),
    height: z.number(),
    numResults: z.number(),
    pairKey: z.string(),
    resultKey: z.number(),
    searchId: z.string(),
    transactionId: z.string(),
    width: z.number(),
});

export type PageLoadedEvent = z.infer<typeof pageLoadedEventSchema>;
export const pageLoadedEventSchema = z.object({
    eventName: z.literal('page_loaded'),
    capturedAt: z.number(),
    userAgent: z.string(),
});

export type SearchCompletedEvent = z.infer<typeof searchCompletedEventSchema>;
const searchCompletedEventSchema = z.object({
    eventName: z.literal('search_completed'),
    capturedAt: z.number(),
    searchId: z.string(),
    transactionId: z.string(),
    zipCode: z.string(),
    results: z.array(
        z.object({
            clickoutSearchResultItemId: z.string(),
        }),
    ),
});

export type SearchedByIdEvent = z.infer<typeof searchedByIdEventSchema>;
const searchedByIdEventSchema = z.object({
    eventName: z.literal('search_by_id'),
    capturedAt: z.number(),
    accreditations: z.string().optional(),
    degreeLevel: DegreeSchema.optional(),
    fieldOfStudy: z.string().optional(),
    major: z.string().optional(),
    modality: ModalitySchema.optional(),
    programLength: ProgramLengthSchema.optional(),
    requirements: ProgramRequirementsSchema.optional(),
    resultKey: z.number(),
    searchId: z.string(),
    transactionId: z.string(),
});

export type SearchedOnLoadEvent = z.infer<typeof searchedOnLoadEventSchema>;
const searchedOnLoadEventSchema = z.object({
    eventName: z.literal('search_on_load'),
    capturedAt: z.number(),
    accreditations: AccreditationsSchema.optional(),
    degreeLevel: DegreeSchema.optional(),
    fieldOfStudy: z.string().optional(),
    major: z.string().optional(),
    modality: ModalitySchema.optional(),
    programLength: ProgramLengthSchema.optional(),
    requirements: ProgramRequirementsSchema.optional(),
    resultKey: z.number(),
    transactionId: z.string(),
});

// export type TestTriggeredEvent = z.infer<typeof testTriggeredEventSchema>;
// const testTriggeredEventSchema = z.object({
//     eventName: z.literal('test_triggered'),
//     capturedAt: z.number(),
//     testName: z.enum(activeTestNames),
// });

export type ViewMoreClickedEvent = z.infer<typeof viewMoreClickedEventSchema>;
const viewMoreClickedEventSchema = z.object({
    eventName: z.literal('view_more_clicked'),
    capturedAt: z.number(),
    searchId: z.string().optional(),
});

export type DomainEvent = z.infer<typeof domainEventSchema>;
export const domainEventSchema = z.union([
    adClickedEventSchema,
    adLoadedEventSchema,
    cdoViewMoreClickedEventSchema,
    formDropdownClosedEventSchema,
    formDropdownOpenedEventSchema,
    formHeightChangedEventSchema,
    formLoadedEventSchema,
    formSubmittedEventSchema,
    navigatedToExternalResultsSchema,
    noOffersFoundEventSchema,
    resultsHeightChangedEventSchema,
    resultsLoadedEventSchema,
    pageLoadedEventSchema,
    searchCompletedEventSchema,
    searchedByIdEventSchema,
    searchedOnLoadEventSchema,
    // testTriggeredEventSchema,
    viewMoreClickedEventSchema,
]);
