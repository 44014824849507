import type { FormDropdownClosedEvent } from 'common/domain-events';
import { getLoadedFramePair } from 'static/frames/loaded-frames';

// On a FormDropdownClosed event, make all results frame relatively positioned
// (undoing the operation in handleFormDropdownOpened())
export const handleFormDropdownClosed = (params: {
    event: FormDropdownClosedEvent;
}) => {
    const framePair = getLoadedFramePair(params.event.pairKey);
    const resultsFrames = framePair?.results ?? [];
    resultsFrames.forEach((rf) => (rf.frame.style.position = 'relative'));
};
