import { assertUnreachable } from '@libraries/assert-unreachable';

export type QueryStringParams = Record<
    string,
    string | number | boolean | undefined
>;

const stringify = (val: string | number | boolean | undefined) => {
    switch (typeof val) {
        case 'string':
            return val;
        case 'number':
            return String(val);
        case 'boolean':
            return val ? 'true' : undefined;
        case 'undefined':
            return;
        default:
            return assertUnreachable(val);
    }
};

const parseBoolean = (val?: string) => {
    if (val === 'true') return true;
    if (val === 'false') return false;
};

export const serializeQueryString = (
    params: QueryStringParams,
): URLSearchParams => {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
        const stringified = stringify(value);
        if (stringified) searchParams.set(key, stringified);
    }
    return searchParams;
};

export const deserializeQueryString = (params: {
    searchParams: URLSearchParams;
    booleans: Array<string>;
    numbers: Array<string>;
}): QueryStringParams => {
    const record: QueryStringParams = {};
    for (const [key, value] of params.searchParams.entries()) {
        if (params.booleans.includes(key)) {
            record[key] = parseBoolean(value);
        } else if (params.numbers.includes(key)) {
            record[key] = Number(value);
        } else {
            record[key] = value;
        }
    }
    return record;
};
