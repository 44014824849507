import { z } from 'zod';

// c.f. 'placementType' field at
// https://www.campusexplorer.com/api/docs/program-ad-search/1/ NOTE: this list
// includes additional unpublished values, see
// lib/CE/Form/API/ProgramAdSearch.pm in the CE directory for all our internal
// values.

export type PlacementType = z.infer<typeof PlacementTypeSchema>;
export const PlacementTypeSchema = z.enum([
    'Listings - Internal Organic',
    'Listings - Internal Paid',
    'Listings - Third-Party Organic',
    'Listings - Third-Party Paid',
    'Program Listings - Widget',
]);
