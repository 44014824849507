import { initFormFrame } from 'static/frames/form-frame';
import { initResultsFrame } from 'static/frames/results-frame';
import type { HashConfig } from 'static/hash-config';

/*

Frame loaders: code used to insert form and results iframes into the DOM.

*/

// Add frames at load time
export const addFramesToDOM = (params: {
    frameHost: string;
    transactionId: string;
    hashConfig: HashConfig;
}): void => {
    document
        .querySelectorAll<HTMLElement>('.archer-form')
        .forEach((element) => {
            initFormFrame({
                frameHost: params.frameHost,
                transactionId: params.transactionId,
                hashConfig: params.hashConfig,
                element,
            });
        });
    document
        .querySelectorAll<HTMLElement>('.archer-results')
        .forEach((element) => {
            initResultsFrame({
                frameHost: params.frameHost,
                transactionId: params.transactionId,
                hashConfig: params.hashConfig,
                element,
            });
        });
};

// Monitor the DOM and insert new frames as needed
export const observeDOM = (params: {
    frameHost: string;
    transactionId: string;
    hashConfig: HashConfig;
}): void => {
    const callback = (
        mutationList: Array<MutationRecord>,
        _observer: MutationObserver,
    ) => {
        for (const mutation of mutationList) {
            if (mutation.type === 'childList')
                mutation.addedNodes.forEach((node) => {
                    if (node instanceof HTMLElement) {
                        if (node.className.includes('archer-form'))
                            initFormFrame({
                                frameHost: params.frameHost,
                                transactionId: params.transactionId,
                                hashConfig: params.hashConfig,
                                element: node,
                            });
                        else if (node.className.includes('archer-results'))
                            initResultsFrame({
                                frameHost: params.frameHost,
                                transactionId: params.transactionId,
                                hashConfig: params.hashConfig,
                                element: node,
                            });
                    } else console.warn('added node is not an Element');
                });
            else
                console.error(
                    'mutation.type is not childList - this should never happen',
                );
        }
    };

    const observer = new MutationObserver(callback);
    observer.observe(document, { childList: true, subtree: true });
};
