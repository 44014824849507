import { z } from 'zod';

// c.f. 'requirements' field at
// https://www.campusexplorer.com/api/docs/program-ad-search/1/

export type ProgramRequirements = z.infer<typeof ProgramRequirementsSchema>;
export const ProgramRequirementsSchema = z.enum([
    'NO GRE',
    'NO GMAT',
    'NO APPLICATION FEE',
]);
