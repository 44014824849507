import type { SearchedOnLoadEvent } from 'common/domain-events';
import { bubbleEvent } from './bubble-event';

export const handleSearchOnLoad = (params: { event: SearchedOnLoadEvent }) => {
    bubbleEvent({
        event: params.event,
        extra: {
            accreditations: params.event.accreditations,
            degreeLevel: params.event.degreeLevel,
            fieldOfStudy: params.event.fieldOfStudy,
            major: params.event.major,
            modality: params.event.modality,
            programLength: params.event.programLength,
            requirements: params.event.requirements,
            resultKey: params.event.resultKey,
            transactionId: params.event.transactionId,
        },
    });
};
