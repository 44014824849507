import type { AdClickedEvent } from 'common/domain-events';
import { bubbleEvent } from './bubble-event';

export const handleAdClicked = (params: { event: AdClickedEvent }) => {
    bubbleEvent({
        event: params.event,
        extra: {
            clickoutSearchResultItemId: params.event.clickoutSearchResultItemId,
            clickTarget: params.event.clickTarget,
            index: params.event.index,
            programName: params.event.programName,
            schoolName: params.event.schoolName,
            searchId: params.event.searchId,
            transactionId: params.event.transactionId,
            url: params.event.url,
        },
    });
};
