import type { FormDropdownOpenedEvent } from 'common/domain-events';
import { getLoadedFramePair } from 'static/frames/loaded-frames';

// On a FormDropdownOpened event, make all results frame statically positioned.
export const handleFormDropdownOpened = (params: {
    event: FormDropdownOpenedEvent;
}) => {
    const framePair = getLoadedFramePair(params.event.pairKey);
    const resultsFrames = framePair?.results ?? [];
    resultsFrames.forEach((rf) => (rf.frame.style.position = 'static'));
};
