import { z } from 'zod';

// c.f. 'degree' field at
// https://www.campusexplorer.com/api/docs/program-ad-search/1/. We add some
// additional unpublished values, see lib/CE/Form/API/ProgramAdSearch.pm in the
// CE repo for the full list.

export type Degree = z.infer<typeof DegreeSchema>;
export const DegreeSchema = z.enum([
    'Any',
    'assc',
    'bach',
    'cert',
    'master',
    'doctor',
    'grad_cert',
]);
