const debug = (params: Array<string>) => params.includes('debug');

const getHashParamValue = (params: Array<string>, paramName: string) => {
    const name = paramName + '=';
    const param = params.find((param) => param.startsWith(name));
    const value = param?.slice(name.length);

    return value;
};

export type HashConfig = {
    debug?: boolean;
    host?: string;
    themeId?: string;
};

export const getHashConfig = (hash: string): HashConfig => {
    const hashCode = '#campusexplorer/';

    if (!hash.startsWith(hashCode)) return {};

    const hashParams = hash.slice(hashCode.length).split('/');
    return {
        debug: debug(hashParams),
        host: getHashParamValue(hashParams, 'host'),
        themeId: getHashParamValue(hashParams, 'themeId'),
    };
};
