import type { PageLoadedEvent } from 'common/domain-events';
import { bubbleEvent } from './bubble-event';

// Handle tasks triggered by a PageLoaded event
export const handlePageLoaded = (params: { event: PageLoadedEvent }) => {
    // Re-emit the event to our parent page, with added data
    bubbleEvent({
        event: params.event,
        extra: { userAgent: params.event.userAgent },
    });
};
