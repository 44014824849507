import type { Logger } from 'static/logger';

// Handles fallback if a pairKey is not provided in the config
export const getPairKey = (params: {
    pairKey?: string;
    logger: Logger;
}): string => {
    if (params.pairKey) return params.pairKey;
    params.logger.warn('"archerConfig" missing pairKey, using 0');
    return '0';
};
