import { getAllLoadedFramePairs } from 'static/frames/loaded-frames';

export const addPrivacyPolicyToAllFrames = (): void =>
    getAllLoadedFramePairs().forEach((framePair) => {
        if (
            framePair.results &&
            (framePair.searchOnLoad ||
                framePair.results[
                    framePair.results.length - 1
                ]?.frame.src.includes('searchId')) &&
            framePair.results[framePair.results.length - 1]
        ) {
            framePair.results[framePair.results.length - 1]!.frame.src +=
                '&showPrivacyPolicy=true';
        } else if (framePair.form) {
            framePair.form.src += '&showPrivacyPolicy=true';
        } else {
            throw new Error(
                `error with 'addPrivacyPolicy' - no results or form in this iframes map`,
            );
        }
    });
