import { AccreditationsSchema } from 'common/enums/accreditations';
import { DegreeSchema } from 'common/enums/degree';
import { ModalitySchema } from 'common/enums/modality';
import { PlacementTypeSchema } from 'common/enums/placement-type';
import { ProgramLengthSchema } from 'common/enums/program-length';
import { ProgramRequirementsSchema } from 'common/enums/program-requirements';
import {
    booleanCoerceString,
    discardEmptyString,
    optionalStringStripEmpty,
} from 'common/util/zod';
import { resultsLoadRangeSchema } from 'common/values/results-load-range';
import { z } from 'zod';

/*

Defines the configuration parameters supported in an HTML element's "data-archer-config" attribute.

*/

// A schema for the 'data-archer-config' attribute of the 'archer-form' and
// 'archer-results' elements.
export type CommonElementConfig = z.infer<typeof commonElementConfigSchema>;
export const commonElementConfigSchema = z.object({
    accreditations: z.preprocess(
        discardEmptyString,
        AccreditationsSchema.optional(),
    ),
    advertiserDedupe: booleanCoerceString.optional(),
    archeruserzip: optionalStringStripEmpty,
    degree: z.preprocess(discardEmptyString, DegreeSchema.optional()),
    fieldOfStudy: optionalStringStripEmpty,
    major: optionalStringStripEmpty,
    modality: z.preprocess(discardEmptyString, ModalitySchema.optional()),
    pairKey: optionalStringStripEmpty,
    placementType: z.preprocess(
        discardEmptyString,
        PlacementTypeSchema.optional(),
    ),
    programLength: z.preprocess(
        discardEmptyString,
        ProgramLengthSchema.optional(),
    ),
    publisherSourceCode: z.string(),
    publisherTrackingCode: optionalStringStripEmpty,
    requirements: z.preprocess(
        discardEmptyString,
        ProgramRequirementsSchema.optional(),
    ),
    test: booleanCoerceString.optional(),
    themeId: optionalStringStripEmpty,
});

// A schema for the 'data-archer-config' attribute of an 'archer-form' element.
export type FormElementConfig = z.infer<typeof formElementConfigSchema>;
export const formElementConfigSchema = commonElementConfigSchema.extend({
    externalResultsURL: optionalStringStripEmpty,
    hideDegree: booleanCoerceString.optional(),
    hideFieldOfStudy: booleanCoerceString.optional(),
    hideMajor: booleanCoerceString.optional(),
});

// A schema for the 'data-archer-config' attribute of an 'archer-results'
// element.
export type ResultsElementConfig = z.infer<typeof resultsElementConfigSchema>;
export const resultsElementConfigSchema = commonElementConfigSchema.extend({
    hideViewMorePrograms: booleanCoerceString.optional(),
    resultsLoadRange: z.preprocess(
        discardEmptyString,
        resultsLoadRangeSchema.optional(),
    ),
    resultsPerPage: z.preprocess(discardEmptyString, z.number().optional()),
    searchOnLoad: booleanCoerceString.optional(),
});
